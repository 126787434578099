import { useNavigate } from "react-router-dom";

import { CustomTable, PaginationMeta } from "components/table/custom-table";
import { CustomPagination } from "components/table/custom-pagination";
import { ListScheduleHistoryItemTable } from "./schedule-history-tab";

export interface ScheduleHistoryTableProps {
  schedules: ListScheduleHistoryItemTable[] | null;
  meta: PaginationMeta | null;
  onSelectSchedule: (scheduleId: string) => void;
  onChangePage: (page: number) => void;
  tableType: "card" | "table";
  setTableType?: (tableType: "card" | "table") => void;
}
export const ScheduleHistoryTable = ({
  schedules,
  meta,
  onSelectSchedule,
  onChangePage,
  tableType,
  setTableType,
}: ScheduleHistoryTableProps) => {
  const columnNames = {
    patient_name: "Nome Paciente",
    doctor_name: "Nome Profissional",
    type: "Tipo Atendimento",
    date: "Data Atendimento",
  };

  return (
    <>
      <CustomTable
        columnNames={columnNames}
        data={schedules}
        onSelect={onSelectSchedule}
        tableType={tableType}
        setTableType={setTableType}
        btnLabelNotFound=""
        btnClickNotFound={() => {}}
      />
      <CustomPagination
        {...Object.assign({}, meta)}
        onChangePage={onChangePage}
      />{" "}
    </>
  );
};
