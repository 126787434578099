import { Badge, Button, Col, ModalFooter, Row } from "reactstrap";
import { format, getDay } from "date-fns";
import { ptBR } from "date-fns/locale";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { actionDeleteSchedule } from "actions/schedule/action-delete-schedule";
import {
  ScheduleTypeColors,
  ScheduleTypeLabels,
} from "domain/schedule/enums/schedule-type.enum";
import { maskedPhoneNumber } from "helpers/phone-formatter";
import "./list-schedule.style.css";
import { ScheduleStatusEnum } from "domain/schedule/enums/schedule-status.enum";
import { actionUpdateStatusSchedule } from "actions/schedule/action-update-status-schedule";
import { ListScheduleDropdown } from "./list-schedule-dropdown";
import { WhatsappIcon } from "components/icons/whatsapp-icon";
import { getWhatsappLink } from "helpers/get-whatsapp-link";
import { ModalDeleteSchedule } from "./modal-delete-schedule";
import { ModalMakeAppointmentPayment } from "./modal-make-appointment-payment";
import { actionMakeAppointmentPayment } from "actions/schedule/action-make-appointment-payment";
import {
  PaymentTypeEnum,
  PaymentTypeLabels,
} from "domain/schedule/enums/payment-type.enum";
import { dateFormatter } from "helpers/date-formatter";
import {
  ScheduleCalendarMakeAppointmentPaymentProps,
  ScheduleDataProps,
} from "../index/tabs/calendar/schedule-calendar-tab";
import { maskedToCurrency } from "helpers/format-money";

export interface ListScheduleProps {
  data: ScheduleDataProps;
  updateStatus: (status: ScheduleStatusEnum) => void;
  onEdit: () => void;
  onCancel: () => void;
  makePayment: (input: ScheduleCalendarMakeAppointmentPaymentProps) => void;
}

export const ListSchedule: React.FC<ListScheduleProps> = ({
  data,
  updateStatus,
  onEdit,
  makePayment,
}: ListScheduleProps) => {
  const navigate = useNavigate();

  const {
    id,
    start,
    end,
    type,
    patient,
    doctor,
    observation,
    status,
    amount,
    amount_paid,
    payment_type,
    paid_at,
    payment_observation,
    health_plan,
  } = data;

  const [toggleDelete, setToggleDelete] = useState(false);
  const [toggleMakeAppointmentPayment, setToggleMakeAppointmentPayment] =
    useState(false);

  const formatDateRange = (): Record<string, string> => {
    const daysOfWeek = [
      "Domingo",
      "Segunda-feira",
      "Terça-feira",
      "Quarta-feira",
      "Quinta-feira",
      "Sexta-feira",
      "Sábado",
    ];

    const dayOfWeek = daysOfWeek[getDay(start)];
    const formattedStartDate = format(start, "dd 'de' MMMM", { locale: ptBR });
    const formattedStartTime = format(start, "HH:mm");
    const formattedEndTime = format(end, "HH:mm");

    return {
      dayOfWeek,
      formattedStartDate,
      formattedStartTime,
      formattedEndTime,
    };
  };

  const formatMsgMedicalRecordDate = () => {
    const {
      dayOfWeek,
      formattedStartDate,
      formattedStartTime,
      formattedEndTime,
    } = formatDateRange();
    return `${dayOfWeek}, ${formattedStartDate} - ${formattedStartTime} às ${formattedEndTime}`;
  };

  const formatMsgMedicalRecordDateWpp = () => {
    const { dayOfWeek, formattedStartDate, formattedStartTime } =
      formatDateRange();
    return `${dayOfWeek}, ${formattedStartDate} -  às ${formattedStartTime}`;
  };

  const onUpdateStatus = async (id: number, status: ScheduleStatusEnum) => {
    try {
      await actionUpdateStatusSchedule(id, { status });
      await updateStatus(status);
    } catch {}
  };

  const onStartMedicalRecord = async () => {
    navigate(`/painel/prontuario/${data.patient.id}`);
  };

  const onMakeAppointmentPayment = async (
    input: ScheduleCalendarMakeAppointmentPaymentProps
  ) => {
    try {
      const payload = {
        amount_paid: input.amount_paid,
        payment_type: input.payment_type,
        paid_at: input.paid_at,
        payment_observation: input.payment_observation,
        health_plan_id: input.health_plan?.id,
      };
      await actionMakeAppointmentPayment(id, payload);

      await makePayment(input);
      setToggleMakeAppointmentPayment(false);
    } catch {}
  };

  const onDeleteSchedule = async (id: number) => {
    try {
      await actionDeleteSchedule(id);
      window.location.reload();
    } catch {}
  };

  const getMessageWhatsapp = () => {
    return `CONFIRMAÇÃO%20DE%20AGENDAMENTO%20🕐%0AOlá%2C%20${
      patient.name
    }%0A%0AGostaríamos%20de%20confirmar%20seu%20horário%20*${formatMsgMedicalRecordDateWpp()}*%20para%20realizar%20${ScheduleTypeLabels(
      type
    )}%20com%20Dr(a).%20${
      doctor.name
    }%0A%0AEm%20caso%20de%20atraso%20ou%20remarcação%2C%20por%20favor%2C%20entre%20em%20contato%20conosco.`;
  };

  const getPaymentType = (paymentType: any, healthPlanName: any) => {
    if (paymentType) {
      let result = PaymentTypeLabels(paymentType);

      if (healthPlanName) {
        result += ` (${healthPlanName})`;
      }
      return result;
    } else return "N/A";
  };
  return (
    <>
      <Row className="p-0">
        <Row className="mb-3">
          <Row className="my-2">
            <Col sm="12" md="3" className="form-label font-weight-500">
              Agendamento
            </Col>
            <Col sm="12" md="9">
              <Badge
                color={ScheduleTypeColors[type]["class"]}
                className="px-3 py-2 fs-15 font-weight-500"
              >
                {" "}
                {ScheduleTypeLabels(type)}
              </Badge>
            </Col>
          </Row>

          <Row className="my-2">
            <Col sm="12" md="3" className="form-label  font-weight-500">
              Médico
            </Col>
            <Col sm="12" md="9">
              <i className="fas fa-user-doctor text-primary"></i> {doctor.name}
            </Col>
          </Row>

          <Row className="my-2">
            <Col sm="12" md="3" className="form-label  font-weight-500">
              Paciente
            </Col>
            <Col sm="12" md="9">
              <i className="fas fa-hospital-user text-primary"></i>{" "}
              {patient.name}
            </Col>
          </Row>

          <Row className="my-2">
            <Col sm="12" md="3" className="form-label  font-weight-500">
              Telefone
            </Col>
            <Col sm="12" md="9">
              <WhatsappIcon />
              {maskedPhoneNumber(patient.phone)}
            </Col>
          </Row>

          <Row>
            <Col sm="12" md="3" className="form-label  font-weight-500">
              Data/hora
            </Col>
            <Col sm="12" md="9">
              <i className="fas fa-calendar-days text-primary"></i>{" "}
              {formatMsgMedicalRecordDate()}
            </Col>
          </Row>

          <Row className="my-2">
            <Col sm="12" md="3" className="form-label  font-weight-500">
              Status
            </Col>
            <Col sm="12" md="9">
              <ListScheduleDropdown
                onUpdateStatus={onUpdateStatus}
                id={id}
                currentStatus={status}
              />
            </Col>
          </Row>

          {status === ScheduleStatusEnum.SCHEDULED && patient.phone && (
            <Row>
              <Col sm="12" md="3" className="form-label  font-weight-500">
                Confirmar agendamento
              </Col>
              <Col sm="12" md="9">
                <div style={{ width: "fit-content" }}>
                  <a
                    href={getWhatsappLink(
                      `+55${patient.phone}`,
                      getMessageWhatsapp()
                    )}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <div
                      className={`dropdown-menu-button font-weight-500`}
                      style={{ border: "1px solid #198754", cursor: "pointer" }}
                    >
                      <WhatsappIcon />
                      Enviar lembrete de confirmação
                    </div>
                  </a>
                </div>
              </Col>
            </Row>
          )}

          <Row className="my-1">
            <Col sm="12" md="3" className="form-label font-weight-500">
              Observação
            </Col>
            <Col sm="12" md="9">
              {observation ? observation : "Sem observações"}
            </Col>
          </Row>

          <hr />

          <Row className="my-1">
            <Col sm="12" md="3" className="form-label font-weight-500">
              Valor {ScheduleTypeLabels(type)}
            </Col>
            <Col sm="12" md="9">
              <strong>{amount}</strong>
            </Col>
          </Row>

          {payment_type && (
            <>
              <Row>
                <Col sm="12" md="3" className="form-label font-weight-500">
                  Método de pagamento
                </Col>
                <Col sm="12" md="9">
                  <strong>
                    {getPaymentType(payment_type, health_plan?.name)}
                  </strong>
                </Col>
              </Row>
            </>
          )}
          {!!Number(maskedToCurrency(amount_paid ?? "0")) && paid_at && (
            <>
              <Row>
                <Col sm="12" md="3" className="form-label font-weight-500">
                  Valor Pago
                </Col>
                <Col sm="12" md="9">
                  <strong>{amount_paid}</strong>
                </Col>
              </Row>
              <Row>
                <Col sm="12" md="3" className="form-label font-weight-500">
                  Data pagamento
                </Col>
                <Col sm="12" md="9">
                  <strong>{dateFormatter(paid_at, "dd/MM/yyyy")}</strong>
                </Col>
              </Row>

              {payment_observation && (
                <Row>
                  <Col sm="12" md="3" className="form-label font-weight-500">
                    Descrição pagamento
                  </Col>
                  <Col sm="12" md="9">
                    {payment_observation}
                  </Col>
                </Row>
              )}
            </>
          )}
        </Row>

        <ModalFooter className="px-0">
          <Col className="d-flex justify-content-between flex-wrap">
            <Col className="d-flex col-12 col-md-12 col-xl-6 justify-content-start  flex-wrap ">
              <div className="col-12 col-md-6 col-xl-3 mt-2 px-1">
                <Button
                  color="danger-secondary"
                  className="form-control"
                  onClick={() => setToggleDelete(true)}
                >
                  <span className="fs-14">Excluir</span>
                </Button>
              </div>
              <div className="col-12 col-md-6 col-xl-6 mt-2 px-1">
                <Button
                  color="outline-info"
                  className="form-control"
                  onClick={() => setToggleMakeAppointmentPayment(true)}
                >
                  <span className="fs-14">Lançar pagamento</span>
                </Button>
              </div>
            </Col>

            <Col className="d-flex col-12 col-md-12 col-xl-6 justify-content-end flex-wrap ">
              <div className="col-12 col-md-6 col-xl-5 mt-2 px-1">
                <Button
                  color="secondary"
                  onClick={onEdit}
                  className=" form-control"
                >
                  Editar
                </Button>
              </div>
              <div className="col-12 col-md-6 col-xl-7 mt-2 px-1">
                <Button
                  color="primary"
                  onClick={onStartMedicalRecord}
                  className=" form-control"
                >
                  Iniciar atendimento
                </Button>
              </div>
            </Col>
          </Col>
        </ModalFooter>

        <ModalDeleteSchedule
          isOpen={toggleDelete}
          toggle={() => setToggleDelete((prev) => !prev)}
          onSubmit={async () => await onDeleteSchedule(id)}
        />

        <ModalMakeAppointmentPayment
          isOpen={toggleMakeAppointmentPayment}
          toggle={() => setToggleMakeAppointmentPayment((prev) => !prev)}
          data={data}
          onSubmit={onMakeAppointmentPayment}
        />
      </Row>
    </>
  );
};
