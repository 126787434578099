import { observer } from "mobx-react";
import { useState } from "react";
import _ from "lodash";

import { CustomTab } from "components/tab/custom-tab";

import UserIdentity from "domain/user/entities/user-identity";
import { UserRoleEnum } from "domain/user/entities/enums/user-role.enum";

const Employees: React.FC = observer(() => {
  const [activeTab, setActiveTab] = useState("0");

  const [tabs, setTabs] = useState([]);

  return (
    <>
      <div className="main-wrapper">
        <CustomTab
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </div>
    </>
  );
});
export default Employees;
