import { ListMedicalRecord } from "services/medical-record-service";
import { MedicalRecordAnamensisItem } from "./components/medical-record-anamnesis-item";
import { MedicalRecordExamsItem } from "./components/medical-record-exams-item";
import { MedicalRecordProceduresItem } from "./components/medical-record-procedures-item";
import { MedicalRecordAttachmentsItem } from "./components/medical-record-attachments-item";
import { MedicalRecordEstimatedReturnDateItem } from "./components/medical-record-estimated-return-date-item";

export const MedicalRecordItem: React.FC<ListMedicalRecord> = (item) => {
  return (
    <>
      <div className="d-flex row px-3">

        <div className="mt-3">
          {item.estimated_return_date && (
            <MedicalRecordEstimatedReturnDateItem
              estimated_return_date={item.estimated_return_date}
            />
          )}
        </div>
        <div className="mt-3">
          {item.anamnesis && <MedicalRecordAnamensisItem {...item.anamnesis} />}
        </div>
        <div className="mt-3">
          {item.exams && <MedicalRecordExamsItem exams={item.exams} />}
        </div>
        <div className="mt-3">
          {item.procedures && (
            <MedicalRecordProceduresItem procedures={item.procedures} />
          )}
        </div>
        <div className="mt-3">
          {item.attachments && (
            <MedicalRecordAttachmentsItem attachments={item.attachments} />
          )}
        </div>
      </div>
    </>
  );
};
