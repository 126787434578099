import { Col, Row } from "reactstrap";
import {
  ScheduleTypeEnum,
  ScheduleTypeLabels,
} from "domain/schedule/enums/schedule-type.enum";
import { dateFormatter } from "helpers/date-formatter";
import {
  PaymentTypeEnum,
  PaymentTypeLabels,
} from "domain/schedule/enums/payment-type.enum";
import { labelMaskFormatToReal } from "helpers/format-money";
import {
  ScheduleStatusEnum,
  ScheduleStatusLabels,
} from "domain/schedule/enums/schedule-status.enum";
import {
  ScheduleOriginContactEnum,
  ScheduleOriginContactLabels,
} from "domain/schedule/enums/schedule-origin-contact.enum";
import { ListAllScheduleItems } from "services/schedule-service";

interface ScheduleHistoryDetailProps {
  data: ListAllScheduleItems | null;
}

export const ScheduleHistoryDetail: React.FC<ScheduleHistoryDetailProps> = ({
  data,
}) => {
  const getPaymentType = (data: ListAllScheduleItems) => {
    if (data.payment_type) {
      let result = PaymentTypeLabels(data.payment_type);

      if (data.health_plan?.name) {
        result += ` (${data.health_plan?.name})`;
      }
      return result;
    } else return "N/A";
  };

  return (
    <>
      {data && (
        <>
        <Row className="p-0">
          <Row className="mb-3">
            <Row className="my-2">
              <Col sm="12" md="5" className="form-label  font-weight-500">
                Nome Profissional
              </Col>
              <Col sm="12" md="7">
                {data.doctor?.name}
              </Col>
            </Row>

            <Row className="my-2">
              <Col sm="12" md="5" className="form-label  font-weight-500">
                Nome Paciente
              </Col>
              <Col sm="12" md="7">
                {data.patient?.name}
              </Col>
            </Row>
            <Row className="my-2">
              <Col sm="12" md="5" className="form-label  font-weight-500">
                Tipo Atendimento
              </Col>
              <Col sm="12" md="7">
                {ScheduleTypeLabels(data.type as unknown as ScheduleTypeEnum)}
              </Col>
            </Row>

            <Row className="my-2">
              <Col sm="12" md="5" className="form-label  font-weight-500">
                Status Atendimento
              </Col>
              <Col sm="12" md="7">
                {ScheduleStatusLabels(
                  data.status as unknown as ScheduleStatusEnum
                )}
              </Col>
            </Row>

            <Row className="my-2">
              <Col sm="12" md="5" className="form-label  font-weight-500">
                Data Atendimento
              </Col>
              <Col sm="12" md="7">
                {dateFormatter(data.start)}
              </Col>
            </Row>

            <Row className="my-2">
              <Col sm="12" md="5" className="form-label  font-weight-500">
                Valor Atendimento
              </Col>
              <Col sm="12" md="7">
                {labelMaskFormatToReal(String(data.amount))}
              </Col>
            </Row>

            <Row className="my-2">
              <Col sm="12" md="5" className="form-label  font-weight-500">
                Método Pagamento
              </Col>
              <Col sm="12" md="7">
                {getPaymentType(data)}
              </Col>
            </Row>

            <Row className="my-2">
              <Col sm="12" md="5" className="form-label  font-weight-500">
                Data Pagamento
              </Col>
              <Col sm="12" md="7">
                {data.amount_paid
                  ? dateFormatter(data.paid_at, "dd/MM/yyyy")
                  : "Não Pago"}
              </Col>
            </Row>

            <Row className="my-2">
              <Col sm="12" md="5" className="form-label  font-weight-500">
                Valor Pago
              </Col>
              <Col sm="12" md="7">
                {data.amount_paid
                  ? labelMaskFormatToReal(String(data.amount_paid))
                  : "Não Pago"}
              </Col>
            </Row>

            <Row className="my-2">
              <Col sm="12" md="5" className="form-label  font-weight-500">
                Origem Contato
              </Col>
              <Col sm="12" md="7">
                {data.origin_contact
                  ? ScheduleOriginContactLabels(
                      data.origin_contact as unknown as ScheduleOriginContactEnum
                    )
                  : "Não informado"}
              </Col>
            </Row>

            <Row className="my-2">
              <Col sm="12" md="5" className="form-label  font-weight-500">
                Descrição pagamento
              </Col>
              <Col sm="12" md="7">
                {data.payment_observation ?? "N/A"}
              </Col>
            </Row>
          </Row>
        </Row>
      </>
    )}
  </>
  );
};
