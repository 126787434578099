import { useState } from "react";

import { CustomTab } from "components/tab/custom-tab";
import ScheduleCalendarTab from "./tabs/calendar/schedule-calendar-tab";
import { ScheduleHistoryTab } from "./tabs/history/schedule-history-tab";
import { MakeAppointment } from "./tabs/make-appointment/make-appointment";

const ScheduleContainer: React.FC = () => {
  const [activeTab, setActiveTab] = useState("0");

  const listTabs = [
    {
      label: "Calendário",
      disabled: false,
      element: (
        <div className="mt-4">
          <ScheduleCalendarTab />
        </div>
      ),
    },
    {
      label: "Histórico de Agendamentos",
      disabled: false,
      element: (
        <div className="mt-4">
          <ScheduleHistoryTab />
        </div>
      ),
    },
  ];

  const [tabs, setTabs] = useState(listTabs);

  return (
    <>
      <div className="main-wrapper">
        <CustomTab
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </div>
    </>
  );
};
export default ScheduleContainer;
