import { useState } from "react";
import { actionListAllSchedule } from "actions/schedule/action-list-all-schedule";
import { ListAllScheduleItems } from "services/schedule-service";
import {
  Badge,
  Button,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import {
  SelectDoctorState,
  SelectDoctorStateProps,
} from "components/form/input/select-doctor-state-form";
import UserIdentity from "domain/user/entities/user-identity";
import { PaginationMeta } from "components/table/custom-table";
import {
  ScheduleTypeAlias,
  ScheduleTypeEnum,
  ScheduleTypeLabels,
} from "domain/schedule/enums/schedule-type.enum";
import { dateFormatter } from "helpers/date-formatter";
import { ScheduleHistoryTable } from "./schedule-history-table";
import { ScheduleHistoryDetail } from "./schedule-history-detail";
import { getTableType } from "helpers/screen";

const OFFSET_DAYS = 1;
const PAGE_SIZE = 20;

interface FormValues {
  start?: Date;
  end?: Date;
  limit: number;
  page: number;
  patient_name?: string;
  doctor_id?: string;
}

export interface ListScheduleHistoryItemTable {
  id: number;
  patient_name: any;
  doctor_name: any;
  type: any;
  date: any;
}

export const ScheduleHistoryTab = () => {
  const [rawData, setRawData] = useState<ListAllScheduleItems[]>([]);
  const [meta, setMeta] = useState<PaginationMeta | null>(null);
  const [tableType, setTableType] = useState<"card" | "table">(getTableType());
  const [data, setData] = useState<ListScheduleHistoryItemTable[]>([]);
  const [selectedSchedule, setSelectedSchedule] =
    useState<ListAllScheduleItems | null>(null);

  const [formValues, setFormValues] = useState<FormValues>({
    start: undefined,
    end: undefined,
    patient_name: undefined,
    doctor_id: undefined,
    limit: PAGE_SIZE,
    page: 1,
  });

  const onChangePage = async (page: number) => {
    if (page) {
      setFormValues({ ...formValues, page });
    }

    await fetchData(page);
  };

  const fetchData = async (page?: number) => {
    try {
      const response = await actionListAllSchedule({
        ...formValues,
        start: formValues.start
          ? new Date(formValues.start).toISOString()
          : undefined,
        end: formValues.end
          ? new Date(formValues.end).toISOString()
          : undefined,
        page: page ?? formValues.page,
      });

      const result = response.items.map((item) => ({
        id: item.id,
        patient_name: patientItemName(item.patient.name, String(item.id)),
        doctor_name: doctorItemName(item.doctor.name, String(item.id)),
        type: typeItem(item.type, String(item.id)),
        date: scheduleDate(item.start, String(item.id)),
      }));

      setMeta(response.meta);
      setRawData(response.items);
      setData(result);
    } catch {}
  };

  const onViewSchedule = (scheduleId: string) => {
    const result = rawData?.find((item) => item.id === Number(scheduleId));
    if (result) {
      setSelectedSchedule(result);
    }
  };

  const patientItemName = (name: string, scheduleId: string) => {
    return (
      <div className="w-100">
        <span>{name}</span>
      </div>
    );
  };

  const doctorItemName = (name: string, scheduleId: string) => {
    return (
      <div className="w-100">
        <span>{name}</span>
      </div>
    );
  };

  const typeItem = (type: number, scheduleId: string) => {
    return (
      <div className="w-100">
        <Badge
          color={ScheduleTypeAlias(type as unknown as ScheduleTypeEnum)}
          className="px-3 py-2 fs-10"
        >
          {ScheduleTypeLabels(type as unknown as ScheduleTypeEnum)}
        </Badge>
      </div>
    );
  };

  const scheduleDate = (date: string, scheduleId: string) => {
    return (
      <div className="w-100">
        <span>{dateFormatter(date)}</span>
      </div>
    );
  };

  return (
    <>
      <div className="d-flex col-12 flex-wrap justify-content-end">
        {!UserIdentity.isDoctor() && (
          <div className="col-12 col-md-12 col-xl-4 px-2">
            <SelectDoctorState
              label="Profissional"
              setSelectedDoctor={(value: SelectDoctorStateProps) => {
                setFormValues({
                  ...formValues,
                  doctor_id: value?.value ? String(value?.value) : undefined,
                });
              }}
            />
          </div>
        )}

        <FormGroup className="col-12 col-md-12 col-xl-4 px-2">
          <Label htmlFor="name">Digite nome do paciente</Label>
          <InputGroup>
            <Input
              type="text"
              onChange={(e) => {
                setFormValues({
                  ...formValues,
                  patient_name: e.target.value,
                });
              }}
              placeholder="Digite o nome do paciente"
              className="border-right-0"
            />
          </InputGroup>
        </FormGroup>
        <FormGroup className="col-6 col-md-6 col-xl-2 px-2">
          <Label htmlFor="name">Data Inicial</Label>
          <InputGroup>
            <Input
              type="date"
              onChange={(e) => {
                const date = e.target.value
                  ? new Date(e.target.value)
                  : undefined;

                setFormValues({
                  ...formValues,
                  start: date,
                });
              }}
              className="border-right-0"
            />
          </InputGroup>
        </FormGroup>
        <FormGroup className="col-6 col-md-6 col-xl-2 px-2">
          <Label htmlFor="name">Data Final</Label>
          <InputGroup>
            <Input
              type="date"
              onChange={(e) => {
                const date = e.target.value
                  ? new Date(e.target.value)
                  : undefined;

                setFormValues({
                  ...formValues,
                  end: date,
                });
              }}
              className="border-right-0"
            />
          </InputGroup>
        </FormGroup>

        <FormGroup className="col-12 col-md-12 col-xl-2 px-2 d-flex align-items-end">
          <Button
            color="primary"
            className="form-control"
            onClick={() => fetchData()}
          >
            Filtrar
          </Button>
        </FormGroup>
      </div>

      <div>
        <ScheduleHistoryTable
          schedules={data}
          meta={meta}
          onSelectSchedule={onViewSchedule}
          onChangePage={onChangePage}
          tableType={tableType}
          setTableType={setTableType}
        />
      </div>

      <Modal
        isOpen={selectedSchedule !== null}
        toggle={() => setSelectedSchedule(null)}
        size="md"
      >
        <ModalHeader toggle={() => setSelectedSchedule(null)}>
          Detalhes
        </ModalHeader>
        <ModalBody>
          <ScheduleHistoryDetail data={selectedSchedule} />
        </ModalBody>
      </Modal>
    </>
  );
};
