import { FormGroup, Label } from "reactstrap";
import AsyncSelect from "react-select/async";
import { useFormikContext } from "formik";
import { debounce } from "lodash";
import { useEffect } from "react";

import { actionFetchAllDoctors } from "actions/users/action-fetchall-doctors";
import { UserRoleEnum } from "domain/user/entities/enums/user-role.enum";
import UserIdentity from "domain/user/entities/user-identity";
import { customStyles } from "./helpers/react-select-styles";

export interface DoctorSelectableProps {
  value: number;
  label: string;
}

interface SelectDoctorFormProps {
  selectedDoctor?: any;
  setSelectedDoctor?: any;
  isReadOnly?: boolean;
}
export const SelectDoctorForm: React.FC<SelectDoctorFormProps> = ({
  selectedDoctor,
  setSelectedDoctor,
  isReadOnly,
}: SelectDoctorFormProps) => {
  const { setFieldValue, values, errors } = useFormikContext();

  const isDoctor = (): Boolean =>
    UserIdentity.hasRole([UserRoleEnum.DOCTOR, UserRoleEnum.OWNER_DOCTOR, UserRoleEnum.ADMIN_DOCTOR]);

  useEffect(() => {
    if (isDoctor()) {
      setFieldValue("doctor", {
        value: UserIdentity.getData().user_id,
        label: UserIdentity.getData().user_name,
      });
      // setSelectedDoctor({
      //   value: UserIdentity.getData().user_id,
      //   label: UserIdentity.getData().user_name,
      // });
    }
  }, []);

  const loadDoctors = debounce(async (inputValue: string, callback: any) => {
    try {
      const response = await actionFetchAllDoctors({
        name: inputValue,
        limit: 10,
        page: 1,
        isSelectable: true,
      });

      const options = response.items.map((user: any) => ({
        value: user.id,
        label: user.name,
      }));

      callback(options);
    } catch (error) {
      console.error("Error fetching users:", error);
      callback([]);
    }
  }, 700);

  const handleSelectChange = (selectedOption: any) => {
    setFieldValue("doctor", selectedOption);
    // setSelectedDoctor(selectedOption);
    // setFieldValue("doctor", UserIdentity.getData().user_id);
  };

  return (
    <>
      {!isDoctor() ? (
        <FormGroup className="col-12 px-0">
          <Label htmlFor="name">Profissional</Label>
          <AsyncSelect
            cacheOptions
            defaultOptions
            name="doctor"
            id="doctor"
            placeholder="Selecione um profissional"
            value={(values as any)?.doctor}
            loadOptions={loadDoctors as any}
            onChange={handleSelectChange}
            isDisabled={isReadOnly}
            styles={customStyles}
          />
          {(errors as any)?.doctor && (
            <div className="custom-invalid-feedback">Profissional é obrigatório</div>
          )}
        </FormGroup>
      ) : (
        <></>
      )}
    </>
  );
};
