import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { Button, FormGroup, Label, Row } from "reactstrap";

import { CustomFormInput } from "components/form/input/custom-form-input";
import { SelectableFormValue } from "components/form/input/interfaces/selectable-form-value";
import { SetCreatePatientAdditionalData } from "state/patient/create/actions/set-create-patient";
import { SelectHealthPlanForm } from "components/form/input/select-health-plan-form";

export interface FormPatientAdditionalDataProps {
  data: PatientAdditionalDataFormValues;
  onSubmit: (data: PatientAdditionalDataFormValues) => void;
  onBack: () => void;
  isReadOnly?: boolean;
}

export interface PatientAdditionalDataFormValues {
  profession: string;
  father_name: string;
  mother_name: string;
  spouse: string;
  patient_insurance_number: string;
  health_plan: SelectableFormValue | null;
  observation: string;
}

export const FormPatientAdditionalData: React.FC<
  FormPatientAdditionalDataProps
> = ({ data, onBack, onSubmit, isReadOnly }) => {
  const validationSchema = Yup.object().shape({});

  const initialValues: PatientAdditionalDataFormValues = {
    profession: data.profession,
    father_name: data.father_name,
    mother_name: data.mother_name,
    spouse: data.spouse,
    patient_insurance_number: data.patient_insurance_number,
    health_plan: data.health_plan ?? null,
    observation: data.observation,
  };

  const handleSubmit = async (values: PatientAdditionalDataFormValues) => {
    console.log("values", values);
    SetCreatePatientAdditionalData(values);
    return onSubmit(values);
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <Row className="d-flex col-12 mx-0">
              <FormGroup className="col-12 col-md-6 ">
                <Label htmlFor="profession">Profissão</Label>
                <Field
                  name="profession"
                  id="profession"
                  placeholder="Profissão"
                  type="text"
                  component={CustomFormInput}
                  disabled={isReadOnly}
                />
              </FormGroup>
              <FormGroup className="col-12 col-md-6 ">
                <Label htmlFor="father_name">Nome Pai</Label>
                <Field
                  name="father_name"
                  id="father_name"
                  placeholder="Nome Pai"
                  type="text"
                  component={CustomFormInput}
                  disabled={isReadOnly}
                />
              </FormGroup>
            </Row>
            <Row className="d-flex col-12 mx-0">
              <FormGroup className="col-12 col-md-6 ">
                <Label htmlFor="mother_name">Nome Mãe</Label>
                <Field
                  name="mother_name"
                  id="mother_name"
                  placeholder="Nome Mãe"
                  type="text"
                  component={CustomFormInput}
                  disabled={isReadOnly}
                />
              </FormGroup>
              <FormGroup className="col-12 col-md-6 ">
                <Label htmlFor="spouse">Cônjuge</Label>
                <Field
                  name="spouse"
                  id="spouse"
                  placeholder="Cônjuge"
                  type="text"
                  component={CustomFormInput}
                  disabled={isReadOnly}
                />
              </FormGroup>
            </Row>
            <Row className="d-flex col-12 mx-0">
              <FormGroup className="col-12 col-md-6">
                <SelectHealthPlanForm isReadOnly={isReadOnly} />
              </FormGroup>
              <FormGroup className="col-12 col-md-6">
                <Label htmlFor="patient_insurance_number">
                  Número carteirinha
                </Label>
                <Field
                  name="patient_insurance_number"
                  id="patient_insurance_number"
                  placeholder="Número carteirinha"
                  type="text"
                  component={CustomFormInput}
                  disabled={isReadOnly}
                />
              </FormGroup>
            </Row>

            <Row className="d-flex col-12 mx-0">
              <FormGroup className="col-12 ">
                <Label htmlFor="observation">Observação</Label>
                <Field
                  name="observation"
                  id="observation"
                  placeholder="Observação do Paciente"
                  type="text"
                  component={CustomFormInput}
                  disabled={isReadOnly}
                />
              </FormGroup>
            </Row>

            <Row className="d-flex col-12 mx-0 mt-2 justify-content-end">
              <Row className="d-flex col-12 col-md-3 mx-0">
                <Button
                  color="secondary"
                  className="form-control"
                  onClick={onBack}
                >
                  Voltar
                </Button>
              </Row>
              {!isReadOnly && (
                <Row className="d-flex col-12 col-md-3 px-0 mx-0">
                  <Button
                    type="submit"
                    color="primary"
                    className="form-control"
                  >
                    Salvar
                  </Button>
                </Row>
              )}
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};
